import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { obtenirPage } from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"
import LayoutEN from "../../composants/layout/en/LayoutEN"
import Section from "../../composants/layout/Section"

export const requete = graphql`
  {
    pageConditionGeneralesDeVente: allPagesCompilationYaml(
      filter: { code: { eq: "conditionsGeneralesDeVenteEN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function TermsOfSales() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageConditionGeneralesDeVente")

  return (
    <LayoutEN page={page}>
      <section className="section-conteneur">
        <p>
          These general conditions of sale define the rights and obligations of
          the parties in connection with the sale of products by Bastien Foucher
          to its customers through his website.
        </p>
        <p>
          The customer declares to have fully understood these general
          conditions of sale and accepted the rights and obligations set out
          below.
        </p>
      </section>
      <Section titre="Art prints">
        <h3>Definitions</h3>
        <ul>
          <li>Author : Bastien Foucher</li>
          <li>Website : www.bastienfoucher.com</li>
          <li>The Customer: any person ordering a print from the Author.</li>
        </ul>

        <h3>Products</h3>
        <p>
          Any print offered for sale on this site. These are original works of
          art, signed and numbered in a limit of 30 copies in all formats.
          Prints are made on demand, no stock is managed by the Author.
        </p>
        <h3>Certificates of authenticity</h3>
        <p>
          All prints are certified and numbered.
          <ul>
            <li>
              The certificates of authenticity of the prints only are sent to
              the Customer by e-mail.
            </li>
            <li>
              The certificates of authenticity of the fine art prints
              (laminating on aluminum-Dibond and framing in an shadow box) will
              be sent by separate postmail to the Customer’s home.
            </li>
          </ul>
        </p>
        <h3>Prices</h3>
        <p>
          The Products are invoiced in euros on the basis of the prices
          indicated on the Website at the time of the order. The indicated
          prices are the final prices, all taxes included and are mentioned in
          the order confirmation sent to the Customer by email. The products
          remain the property of the Author until full payment of the order. The
          prices displayed are inclusive of shipping costs.
        </p>
        <p>
          In the event of an order outside the European Union, any customs
          duties or taxes that may be required are the responsibility of the
          Customer.
        </p>

        <h3>Payment</h3>
        <p>
          The products can be paid by credit card or Paypal. The debit of the
          credit card / Paypal account is made as soon as the order is validated
          on the Website. The Author will not be required to deliver the
          products ordered until payment has been made in full.
        </p>

        <h3>Delivery</h3>
        <p>
          The Author does not commit to a specific delivery time. It undertakes
          however, to inform the Customer as regularly as possible of the
          progress of the execution of his order.
        </p>
        <p>The delivery times generally observed are as follows:</p>
        <ul>
          <li>
            Simple prints without laminating or framing services: less than 10
            days after payment of the order.
          </li>
          <li>
            Prints with laminating service on aluminum-Dibond: less than 15 days
            after payment of the order.
          </li>
          <li>
            Prints with framing service in shadow box: less than 20 days after
            payment of the order.
          </li>
        </ul>
        <p>
          The Author cannot be held responsible for the delivery of a damaged
          package. This responsibility lies with the carrier. If this happens,
          the Customer must refuse delivery of the package, indicate it on the
          delivery note and send a registered letter to the carrier within three
          days, excluding public holidays. The Author must be informed of this
          process. The Author cannot be held responsible for late delivery or
          non-delivery due to loss of package or force majeure (strike, bad
          weather, etc.).
        </p>
        <h3>Returns and refunds</h3>
        <p>
          If the Product received by the Customer does not comply with the order
          or if it has defects, the Customer has a right of return. The Customer
          must then inform the Author of the Product’s non-compliance with the
          order and must return it in its original packaging. Then, after
          agreement with the Customer, the Product will in this case be:
          <ul>
            <li>
              Either exchanged by the Author who will bear all the costs
              incurred.
            </li>
            <li>Either refunded.</li>
          </ul>
        </p>
        <p>
          The Products offered for sale are not in stock and are made on the
          Customer’s request. If the Customer changes his mind after the order,
          the right of withdrawal provided in Article L121-20 of the French
          Consumer Code cannot be exercised in accordance with Article L121-20-2
          of the French Consumer Code.
        </p>
        <h3>Litigation</h3>
        <p>
          In the event of a Litigation, the Author and the Customer undertake to
          find an amicable solution. Otherwise, the dispute will be brought at a
          French competent court.
        </p>
      </Section>
    </LayoutEN>
  )
}
